<template>
  <Layout>
    <template v-slot:content>
      <SidebarCategory
        v-if="showModal"
        :activeCategory="activeCategory"
        @closeModal="toggleCategorySidebar"
        @reloadCategories="reloadCategories"
      ></SidebarCategory>
      <div class="px-4 md:px-20 h-screen py-8">
        <h1 class="text-4xl text-primary">{{ $t("my-categories") }}</h1>
        <div class="mt-4 rounded bg-white shadow">
          <div class="p-4 border-b flex items-center justify-end">
            <button
              @click="toggleCategorySidebar({ name: '', color: '' })"
              class="btn-table"
            >
              <svg
                class="fill-current h-4 w-4 mr-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clip-rule="evenodd"
                />
              </svg>
              {{ $t("add") }}
            </button>
          </div>
          <div class="overflow-x-scroll md:overflow-hidden">
            <table class="table-fixed min-w-full">
              <thead>
                <tr class="bg-gray-100">
                  <th
                    class="uppercase text-primary text-left font-normal leading-loose tracking-wide py-2 px-4 md:w-2/4"
                  >
                    {{ $t("name") }}
                  </th>
                  <th
                    class="uppercase text-primary text-left font-normal leading-loose tracking-wide py-2 px-4 md:w-2/3"
                  ></th>
                  <th
                    class="text-left leading-loose tracking-wide py-2 md:w-1"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="category in categories" :key="category._id">
                  <td class="px-4 py-2 border-b">{{ category.name }}</td>
                  <td class="px-4 py-2 border-b">
                    <span
                      class="p-2 rounded-full text-primary mr-2 inline-block align-middle"
                      :style="{ background: category.color }"
                    ></span>
                  </td>
                  <td class="px-4 py-2 border-b">
                    <svg
                      class="fill-current h-4 w-4 hover:text-primary cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      @click="toggleDropdown(category._id)"
                    >
                      <path
                        d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                      />
                    </svg>
                    <div
                      v-if="showDropdown === category._id"
                      class="absolute rounded border z-40 bg-white right-12 md:right-36 mt-1"
                    >
                      <ul class="w-40">
                        <li
                          class="py-2 px-4 flex items-center rounded-t hover:bg-primary hover:text-white cursor-pointer border-b"
                          @click="toggleCategorySidebar(category)"
                        >
                          <svg
                            class="fill-current w-4 h-4 mr-1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                            />
                            <path
                              fill-rule="evenodd"
                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <span>{{ $t("edit") }}</span>
                        </li>
                        <li
                          class="py-2 px-4 flex items-center rounded-b hover:bg-primary hover:text-white cursor-pointer"
                          @click="deleteCategory(category)"
                        >
                          <svg
                            class="fill-current w-4 h-4 mr-1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <span>{{ $t("delete") }}</span>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "../Layouts/Layout";
import SidebarCategory from "@/components/SidebarCategory";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "Categories",
  components: { Layout, SidebarCategory },
  setup() {
    const categories = ref([]);
    const term = ref("");
    const showDropdown = ref(false);
    const showModal = ref(false);
    const activeCategory = ref({});
    const router = useRouter();

    onMounted(async () => (categories.value = await getCategories()));

    const getCategories = async () => {
      try {
        const { data } = await window.axios.get("/categories");
        return data;
      } catch (error) {
        if (error && error.response.status === 401) {
          window.localStorage.removeItem("authUser");
        }
      }
    };

    const reloadCategories = async () =>
      (categories.value = await getCategories());

    const toggleDropdown = (categoryId) => {
      if (!showDropdown.value) {
        showDropdown.value = categoryId;
      } else {
        showDropdown.value = false;
      }
    };

    const toggleCategorySidebar = (category) => {
      activeCategory.value = category;
      showDropdown.value = !showDropdown.value;
      showModal.value = !showModal.value;
    };

    const deleteCategory = async (category) => {
      try {
        const response = await window.axios.delete(
          "/categories/" + category._id
        );
        categories.value.splice(categories.value.indexOf(category), 1);
        window.emitter.emit("toggleFlash", {
          message: response.data.message,
          type: response.data.type,
        });
      } catch (error) {
        if (error && error.response.status === 401) {
          window.localStorage.removeItem("token");
          router.push("/");
        } else {
          window.emitter.emit("toggleFlash", {
            message: "error",
            type: "error",
          });
        }
      }
    };

    return {
      categories,
      term,
      showDropdown,
      showModal,
      activeCategory,
      reloadCategories,
      toggleDropdown,
      toggleCategorySidebar,
      deleteCategory,
    };
  },
};
</script>
