<template>
  <div
    class="fixed top-0 w-full h-screen bg-black bg-opacity-25 z-50"
    aria-hidden="true"
  >
    <div class="md:w-2/3 h-full relative" @click="$emit('closeModal')"></div>
    <div
      class="fixed overflow-y-auto top-0 right-0 z-45 h-screen w-full md:w-1/3 bg-white border-solid border-t-4 border-primary p-8"
    >
      <div>
        <svg
          class="w-6 h-6 hover:text-red-200 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click="$emit('closeModal')"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div class="mt-10">
        <h1 class="text-4xl text-primary">{{ $t("add-category") }}</h1>
        <form class="mt-6" @submit.prevent="saveCategory">
          <div class="form-block">
            <label for="name" class="block text-sm font-medium text-gray-700"
              >Name</label
            >
            <input
              type="text"
              id="name"
              name="name"
              v-model="category.name"
              placeholder="Name"
              class="mt-1 block w-full shadow-sm border border-gray-300 rounded-md px-2 py-2 text-gray-700"
            />
          </div>
          <div class="form-block mt-4">
            <label
              for="color"
              class="block text-sm font-medium text-gray-700"
              >{{ $t("color") }}</label
            >
            <input
              type="color"
              id="color"
              name="color"
              v-model="category.color"
              placeholder="#e4e4e4"
              pattern="#[a-f0-9]{6}"
              title="hexadecimal color"
              list="colors"
              class="mt-1 h-8 w-1/3 p-1 block shadow-sm border border-gray-300 rounded-md"
            />
            <datalist id="colors">
              <option v-for="color in colors" :key="color">{{ color }}</option>
            </datalist>
          </div>
          <div class="form-block mt-4">
            <button type="submit" class="btn-primary">{{ $t("save") }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, toRef } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "SidebarCategory",
  emits: ["closeModal", "reloadCategories"],
  props: ["activeCategory"],
  setup(props, { emit }) {
    const category = toRef(props, "activeCategory");
    const router = useRouter();
    const colors = [
      "#ef9a9a",
      "#b39ddb",
      "#9fa8da",
      "#90caf9",
      "#80deea",
      "#80cbc4",
      "#a5d6a7",
      "#e6ee9c",
      "#ffe082",
      "#ffcc80",
      "#ffab91",
      "#bcaaa4",
      "#b0bec5",
    ];

    onMounted(() => {
      const randomNumber = Math.floor(Math.random() * Math.floor(13));
      category.value.color = colors[randomNumber];
    });

    const saveCategory = async () => {
      let url = "/categories";
      let method = "POST";

      if (category.value._id) {
        url = `/categories/${category.value._id}`;
        method = "PATCH";
      }

      try {
        const response = await window.axios({
          method,
          url,
          data: { newCategory: category.value },
        });

        emit("closeModal");
        emit("reloadCategories");

        window.emitter.emit("toggleFlash", {
          message: response.data.message,
          type: response.data.type,
        });
      } catch (error) {
        if (error && error.response.status === 401) {
          window.localStorage.removeItem("token");
          router.push("/");
        } else {
          window.emitter.emit("toggleFlash", {
            message: "error",
            type: "error",
          });
        }
      }
    };

    return {
      category,
      colors,
      saveCategory,
    };
  },
};
</script>
